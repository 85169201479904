<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white ">
      <div v-if="detailObj !== null">
        <div style="overflow-y: autos; white-space: nowrap;" class="p-3 cust_card">
          <div :class="!mobileView ? 'flex items-center justify-between' : ''">
            <div :class="!mobileView ? 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 items-center' : 'flex items-center'">
              <div class=" text-text1 font-semibold pr-2" :class="mobileView ? 'heading-5' : 'heading-2'">{{detailObj.campaignName}}</div>
              <div class="flex flex-wrap py-2" v-if="detailObj.isCampaignComplete">
                <div class="border border-primary px-1.5 py-1.5 mr-4 rounded-xl font-semibold text-primary" :class="!mobileView ? 'heading-6:' : 'heading-8'">Campaign is Completed</div>
              </div> 
            </div>
            <div :class="!mobileView ? 'flex items-center gap-3' : 'flex items-center gap-3'">
              <Button v-if="detailObj.firstMessageIsSend" class="" @buttonAction="$router.push({name: 'MessageLog', params: {campaignMasterId: detailObj.campaignMasterId}})" :disabled="isChagesStaff" :btnSize="!mobileView ? 'medium' : 'small'" :textColor="'white'" :btnColor="'primary'" :btnText="'Message Sent Log'"/>
              <div :class="mobileView ? 'heading-5' : 'heading-3'" class=" text-white cursor-pointer bg-primary px-3 py-1 rounded-xl" @click.stop="button1()">
                <i class="fas fa-copy"></i>
              </div>
              <div v-if="!detailObj.isCampaignComplete" :class="mobileView ? 'heading-5' : 'heading-3'" class=" text-white cursor-pointer bg-primary px-3 py-1 rounded-xl" @click.stop="button2()">
                <i class="fas fa-pen-to-square"></i>
              </div>
              <div v-if="!detailObj.isCampaignRunning" :class="mobileView ? 'heading-5' : 'heading-3'" class=" text-white cursor-pointer bg-error px-3 py-1 rounded-xl" @click.stop="popupError = true">
                <i class="fas fa-trash-alt"></i>
              </div>
            </div>
          </div>
          <!-- <div class="heading-3 text-text1">● Basic Detail:</div> -->
          <div class="mt-2">
              <div class="">
                <div class="p-1">
                  <!-- <p class="mb-0">
                    <span class="text-gray4 heading-5 "><p class="mb-0" style="width:173px">Campaign Name:</p></span>
                    <span class="text-text2 heading-5 font-semibold whitespace-pre-line" >{{detailObj.campaignName}}</span>
                  </p> -->
                  <p class="mb-0 flex items-center">
                    <span class="text-gray4 heading-5 "><p class="mb-0" >Opt Out Message:</p></span>
                    <span class="text-text2 heading-5 font-semibold whitespace-pre-line pl-2" v-if="detailObj.optOutMessage !== ''" >{{detailObj.optOutMessage}}</span>
                    <span class="text-text2 heading-5 font-semibold whitespace-pre-line pl-2" v-if="detailObj.optOutMessage === ''" >--</span>
                  </p>
                  <div class="relative flex items-center" v-if="!isChagesStaff">
                    <div class="flex items-center mt-2 " v-if="actualAssignee.length > 0">
                      <div class="text-gray4 heading-5 "><p class="mb-0">Assigned Staff User{{actualAssignee.length > 1 ? 's' : ''}}:</p></div>
                      <div class=" flex gap-2 flex-wrap pl-2">
                        <div class="flex flex-wrap " v-for="(data, index) in actualAssignee" :key="index">
                          <div class="border border-gray4 heading-6 px-2 py-1 grap-3 rounded-md text-text2">{{ data.assigneeFirstName }} {{ data.assigneeLastName }}</div>
                        </div> 
                      </div>
                    </div>
                    <div class="my-2" v-else>
                      <p class="mb-0 flex items-center">
                        <span class="text-gray4 heading-5 "><p class="mb-0" >Assigned Staff User:</p></span>
                        <span class="text-text2 heading-5 font-semibold pl-2" >--</span>
                      </p>
                    </div>
                    <!-- <div class="my-2" v-else>
                      <div class="text-gray4 heading-5 whitespace-pre-line">Due to contact(s) responding to the drip campaign and reply is not sent back, the campaign has not been assigned to any staff member.</div>
                    </div> -->
                    <span v-if="!detailObj.isCampaignComplete" class="text-primary cursor-pointer pl-3 mt-1" @click="changesinStaff(false)"><i class="fas fa-pen-to-square"></i></span>
                  </div>
                  <div class="mt-2 grid lg:grid-cols-2 gap-4" v-if="isChagesStaff">
                    <div class=" items-center grid lg:grid-cols-2">
                      <div class="mb-3 ">
                        <MultiSelect 
                          :inputext="employeeSearchText" 
                          :inputId="'empslectinpuid'"
                          :lableText="'Assign to Staff User'"
                          :placholderText="`Assign to Staff User`"
                          :autoFocus="false"
                          :showArrow="true"
                          :setReadOnly="false"
                          :searchCallApi="false"
                          :showPlus="false"
                          :keyName="'displayText'"
                          :idKey="'assigneeUserId'"
                          :sectionId="'assigneeUserId'"
                          :listId="'employeelistidb123'"
                          :items="currentyList"
                          :selectedValues="tempAssignee"
                          :showselectAll="true"
                          :fieldError="assigniErr !== ''"
                          @keyPressAction="assigniErr = ''"
                          @selectNewForVal="selectEmployee"
                          />
                          <p class="text-error heading-7">{{assigniErr}}</p>
                      </div>
                      <span class="flex items-center pl-4">
                        <div class=" text-white cursor-pointer bg-error px-1.5 rounded-full" @click.stop="changesinStaff(false)">
                          <i class="fas fa-times"></i>
                        </div>
                        <div class=" text-white cursor-pointer bg-primary px-1.5 rounded-full ml-2" @click.stop="changesinStaff(true)">
                          <i class="fas fa-check"></i>
                        </div>
                      </span>
                    </div>
                  </div>
                    <!-- <p class="heading-7 text-error">{{ errorMsg }}</p> -->
                  <p class="mb-0 mt-2 flex items-center" v-if="detailObj.campaignStartDate !== ''">
                    <span class="text-gray4 heading-5 "><p class="mb-0" >Campaign Start Date:</p></span>
                    <span class="text-text2 heading-5 font-semibold whitespace-pre-line pl-2" >{{detailObj.campaignStartDate | StartDateFormate}}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 p-1 pt-0">
              <p class="mb-0 flex items-center">
                <span class="text-gray4 heading-5 "><p class="mb-0" >Start Time:</p></span>
                <span class="text-text2 heading-5 font-semibold pl-2" >{{detailObj.clockInTime | getTime}}</span>
              </p>
              <p class="mb-0 flex items-center">
                <span class="text-gray4 heading-5 "><p class="mb-0" >End Time:</p></span>
                <span class="text-text2 heading-5 font-semibold pl-2" >{{detailObj.clockOutTime | getTime}}</span>
              </p>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 pl-1">
              <div class="mt-1">
                <div class="text-gray4 heading-5 "><p class="mb-0">Selected Day{{detailObj.selectedDay.length > 1 ? 's' : ''}}:</p></div>
                <div class=" flex gap-2 flex-wrap pt-1">
                  <div class="flex flex-wrap" v-for="(data, index) in detailObj.selectedDay" :key="index">
                    <div class="border border-gray4 heading-7 px-2 py-1 grap-3 rounded-md">{{ data.value }}</div>
                  </div> 
                </div>
              </div>
              <div class="mt-3 flex items-start">
                <div class="text-gray4 heading-5 "><p class="mb-0" >Time Zone:</p></div>
                <div class="">
                  <span class="text-text2 heading-5 font-semibold pl-2" >{{detailObj.timeZoneName}}</span>
                  <div class="flex items-center pl-2 -mt-1" :class="mobileView ? 'flex-wrap' : ''">
                    <div class="text-gray4 heading-6 font-semibold ">(Current Date: {{ currentTime }})</div>
                    <div v-if="mobileView" class="text-gray4 heading-8 font-semibold ">(Timezone: {{this.detailObj.timeZoneValue}})</div>
                    <span class="group pl-1" v-if="!mobileView">
                      <span><i class="fa-solid fa-circle-info text-primary pr-1 cursor-pointer"></i></span>
                        <span class="invisible group-hover:visible bg-white  p-2 rounded-md card tooltip">
                          <span class="mb-0 text-text2">Timezone: {{this.detailObj.timeZoneValue}}</span>
                        </span>
                      </span>
                  </div>
                </div>
                <!-- <div class="">
                  <span class="text-text2 heading-5 font-semibold pl-2" >{{detailObj.timeZoneName}}</span>
                  <div class="flex items-center pl-2 -mt-1">
                    <div class="text-gray4 heading-6 font-semibold ">(Current Date: {{ currentTime }})</div>
                    <div class="group pl-1">
                      <p><i class="fa-solid fa-circle-info text-primary pr-1 cursor-pointer"></i></p>
                        <p class="invisible group-hover:visible bg-white absolute p-2 rounded-md card">
                          <span class="mb-0 text-text2">Timezone: {{this.detailObj.timeZoneValue}}</span>
                        </p>
                      </div>
                  </div>
                </div> -->
                <!-- <span class="text-text2 heading-5 font-semibold pl-2">({{getTime(detailObj.timeZoneName)}})</span> -->
              </div>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 pb-2">
              <div class="mt-3">
                <div>
                  <div class="text-gray4 heading-5 "><p class="mb-0">Phone #:</p></div>
                  <span class="text-text2 heading-5 font-semibold" >{{detailObj.campaignTwilioNumbers[0].twilioNumber | formatPhoneNumber}} <span class="heading-7 text-gray4">({{detailObj.campaignTwilioNumbers[0].label}})</span> <span class="heading-7 text-gray4">({{detailObj.campaignTwilioNumbers[0].commPlatformType | capitalizeFirstLetter}})</span></span>
                </div>
              </div>
              <div class="mt-2" v-if="detailObj.campaignContactList !== null">
                <div class="mt-1">
                  <div class="text-gray4 heading-5 "><p class="mb-0">Selected Contact List:</p></div>
                  <div class="gap-2">
                    <div class="" v-for="(data, index) in detailObj.campaignContactList" :key="index">
                      <p class="pl-2 whitespace-pre-line group cursor-pointer transition inline" @click="dataRedirect(data)">
                        <span class="group-hover:text-primary transition"><span class="text-text2 heading-7 transition group-hover:text-primary">●</span> {{ data.contactListName }}</span>
                        <span class="text-gray4 heading-6 pl-1 group-hover:text-primary transition">(Total Contact{{data.totalContacts > 1 ? 's' : ''}}: {{data.totalContacts}})</span>
                        <span class="pl-2 text-primary heading-7 transition"><i class="fa-solid fa-arrow-up-right-from-square"></i></span>
                      </p>
                    </div> 
                  </div>
                </div>
              </div>
              <div class="mt-2" v-if="detailObj.campaignContactList === null">
                <div class="mt-1">
                  <div class="text-gray4 heading-5 "><p class="mb-0">Selected Contact List:</p></div>
                  <div class="gap-2">
                    <span><span class="text-text2 heading-7 pl-1"></span>--</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2" v-if="detailObj.isCampaignRunning">
              <div class="heading-5 text-text2 flex items-center">
                <p class="mb-0">You can send maximum {{detailObj.sendMeesagePerMinute}} messages per minute</p>
              </div>
            </div>
            <div class="" v-if="!detailObj.isCampaignRunning">
              <div class="heading-5 text-text2 flex items-center" v-if="!isChangeValue">
                <p class="mb-0">You can send maximum {{detailObj.sendMeesagePerMinute}} messages per minute</p>
                <!-- <span class="text-primary cursor-pointer pl-2" @click="openRunningChange(false)"><i class="fas fa-pen-to-square"></i></span> -->
              </div>
              <div class="mt-2" v-if="isChangeValue">
                <div class="flex items-center">
                  <div class="text-gray4 heading-5 whitespace-pre-line"><p class="mb-0">Send Max 
                    <input type="number" v-model.number="msgPerMin" :class="errorMsg ? 'border border-error text-error' : 'border border-gray4'" class="page-number-input outline-none ring-0  text-center"/> messages per minute</p>
                  </div>
                  <span class="flex items-center pl-4">
                    <div class=" text-white cursor-pointer bg-error px-1.5 rounded-full" @click.stop="openRunningChange(false)">
                      <i class="fas fa-times"></i>
                    </div>
                    <div class=" text-white cursor-pointer bg-primary px-1.5 rounded-full ml-2" @click.stop="openRunningChange(true)">
                      <i class="fas fa-check"></i>
                    </div>
                  </span>
                </div>
              </div>
              <!-- <p class="heading-7 text-error">{{ errorMsg }}</p> -->
            </div>
            <!-- <div class="dividerLight my-2 mb-0 md:w-2/3 w-full"></div> -->
            <!-- <div class="mt-0">
              <div class="heading-5 font-semibold"><p class="mb-0" :class="detailObj.isActive ? 'text-text2' : 'text-error'">This Campaign is {{detailObj.isActive ? 'Active' : 'Inactive'}}</p></div>
            </div> -->
            <!-- <div class="mt-2" v-if="detailObj.isCampaignRunning">
              <div class="heading-5 text-text2 flex items-center" v-if="!isChangeValue">
                <p class="mb-0">You can send maximum {{detailObj.sendMeesagePerMinute}} messages per minute</p>
                <span class="text-primary cursor-pointer pl-2" @click="openRunningChange(false)"><i class="fas fa-pen-to-square"></i></span>
              </div>
              <div class="mt-2" v-if="isChangeValue">
                <div class="flex items-center">
                  <div class="text-gray4 heading-5 whitespace-pre-line"><p class="mb-0">Send Max 
                    <input type="number" v-model.number="secondPin" :class="secondErrorMsg ? 'border border-error text-error' : 'border border-gray4'" class="page-number-input outline-none ring-0  text-center"/> messages per minute</p>
                  </div>
                  <span class="flex items-center pl-4">
                    <div class=" text-white cursor-pointer bg-error px-1.5 rounded-full" @click.stop="openRunningChange(false)">
                      <i class="fas fa-times"></i>
                    </div>
                    <div class=" text-white cursor-pointer bg-primary px-1.5 rounded-full ml-2" @click.stop="openRunningChange(true)">
                      <i class="fas fa-check"></i>
                    </div>
                  </span>
                </div>
                  <p class="heading-7 text-error">{{ secondErrorMsg }}</p>
              </div>
            </div> -->
            <!-- <div class="mt-2" v-if="!detailObj.isCampaignRunning">
              <div class="text-gray4 heading-5 whitespace-pre-line"><p class="mb-0">Send Max 
                <input type="number" v-model.number="msgPerMin" :class="errorMsg ? 'border border-error text-error' : 'border border-gray4'" class="page-number-input outline-none ring-0  text-center"/> messages per minute</p></div>
              <p class="heading-7 text-error">{{ errorMsg }}</p>
            </div> -->
            <div class="mt-2">
              <div class="py-1" v-for="(data, index) in detailObj.campaignDripMessages" :key="index">
                <div class="pb-4 md:w-2/3 w-full border border-gray3 card rounded-md p-2 my-2 relative">
                  <div class="absolute right-2" v-if="data.isDripSent">
                    <div class="flex items-center">
                      <!-- <span class="pr-1" v-if="data.totalDripSentToLead > 0 && data.totalDripSentToLead !== data.totalDripOnLead"><i class="fas fa-exclamation-triangle fa-beat-fade text-secondary"></i></span>  -->
                      <span class="pr-1" v-if="data.totalDripSentToLead > 0 && data.totalDripSentToLead !== data.totalDripOnLead"><i class="fas fa-rotate fa-spin text-secondary"></i></span> 
                      <span class="pr-1" v-if="data.totalDripSentToLead > 0 && data.totalDripSentToLead === data.totalDripOnLead"><i class="fas fa-check text-success"></i></span> 
                      <span class="flex items-center">
                        <div class="group">
                          <span class="cursor-pointer transition hover:text-primary">{{data.totalDripSentToLead}}</span>
                          <p class="invisible group-hover:visible bg-white absolute p-2 rounded-md card">
                            <span class="mb-0 text-text2">Total Drip Send Count</span>
                          </p>
                        </div>
                        <div class="px-1">/</div>
                        <div class="group">
                          <span class="cursor-pointer transition hover:text-primary">{{data.totalDripOnLead}}</span>
                          <p class="invisible group-hover:visible bg-white absolute p-2 rounded-md card">
                            <span class="mb-0 text-text2">Total Lead Count</span>
                          </p>
                        </div>
                      </span>
                    </div>
                  </div>
                  <p class="mb-0"><span class="text-text2 heading-5 font-semibold" >Drip {{index + 1}} - Text Message</span></p>
                  <div class="border border-gray2 p-2 w-full whitespace-pre-line mt-2">{{data.textToSend}}</div>
                </div>
                <div class="mt-2 cust_card2 p-2 md:w-2/3 border border-gray2 w-full" v-if="detailObj.campaignDripMessages.length >= 2 && index !== detailObj.campaignDripMessages.length - 1">
                  <div class="text-text2 heading-5" >Duration to wait for next step</div>
                  <div class="text-text2 heading-5 font-semibold" >{{data.prevWaitDurationInSec | secondsToTime}}</div>
                </div>
              </div>
            </div>
            <!-- <div class="dividerLight my-2 mb-0 md:w-2/3 w-full"></div> -->
            <div class="mt-4 flex flex-wrap gap-y-2">
              <div class="flex flex-wrap" v-if="detailObj.isCampaignRunning">
                <div class="border border-secondary px-2 py-2 mr-4 grap-3 rounded-xl text-secondary">Campaign is Running</div>
              </div> 
              <Button v-if="!detailObj.isCampaignRunning && !isChangeValue && !detailObj.isCampaignComplete" class="mr-4" @buttonAction="startCampaign()" :disabled="isChagesStaff" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Start Campaign'"/>
              <Button v-if="detailObj.isCampaignRunning" @buttonAction="addNewComp()" :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Stop Campaign'"/>
            </div>
        </div>
      </div>
    </div>
    <button v-show="scY > 200" @click="toTop" class="bg-primary rounded-full h-10 w-10 fixed bottom-3 right-2"><i class="fas fa-caret-up text-white"></i></button>
    <div v-if="istart" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="font-bold text-center">
          <p class="heading-3 text-error">Alert</p>
        </div>
        <div class="divider my-2"></div>
        <!-- <div  class="mb-4">
          <p v-if="countOfErr > 0" class="heading-4 text-error mb-4">We identified <span class="">{{countOfErr}}</span> contacts from this campaign that are also found in current running campaign(s), system will remove all those <span class="">{{countOfErr}}</span> contacts from this campaign</p>
          <p  class="heading-4 text-text2 mb-2">All Opt-out number will be ignored</p>
          <div class="flex items-center ">
            <input type="checkbox" class="h-4 w-4 cursor-pointer text-error" @click="checkboxCheck()" v-model="isGivePermissionToStop" >
            <span class="pl-2 text-text2 cursor-pointer" @click="checkboxCheck()">Skip contacts that has ever replied back once</span>
          </div>
        </div> -->
        <div class="border border-error p-2 heading-6 font-semibold rounded-md my-2 mb-5" v-if="timeCheckError !== ''">
          <span class="text-error"> Note: {{ timeCheckError }}</span>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel & Go back'" @buttonAction="isWorkPnding(false)"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'I Understand'" @buttonAction="isWorkPnding(true)"/>
        </div>
      </div>
    </div>
    <div v-if="popupError" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-bold">
          <p class="heading-3 text-text1">Are you sure?</p>
        </div>
        <div  class="mb-2 font-bold">
          <p class="heading-6 text-text1">Once deleted all data related to this campaign will be deleted..</p>
        </div>
        <div class="mb-5">
          <TextField
              :inputId="'idforremove'"
              :showcharLimit="false"
              :inputext="yesinputText"
              :lableText="`Type 'Yes' in below text box to delete campaign`"
              :autoFocus="true"
              @inputChangeAction="(data) => yesinputText = data"  />
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="popupError = false"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" :disabled="yesinputText.toLowerCase() !== 'yes'" @buttonAction="deleteCampign"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import Button from '@/View/components/globalButton.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
// import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
import TextField from '@/View/components/textfield.vue'
export default {
  components: {
    Button,
    MultiSelect,
    TextField
  },
  mixins: [deboucneMixin],
  data () {
    return {
      assigniErr: '',
      currentyList: [],
      tempAssignee: [],
      actualAssignee: [],
      employeeSearchText: '',
      currentTime: '',
      yesinputText: '',
      countOfErr: 0,
      isChagesStaff: false,
      isChangeValue: false,
      timeCheckError: '',
      dayVal: [
        {id: 0, value: 'Sunday', isSelected: false}, 
        {id: 1, value: 'Monday', isSelected: false}, 
        {id: 2, value: 'Tuesday', isSelected: false}, 
        {id: 3, value: 'Wednesday', isSelected: false}, 
        {id: 4, value: 'Thursday', isSelected: false}, 
        {id: 5, value: 'Friday', isSelected: false}, 
        {id: 6, value: 'Saturday', isSelected: false}
      ],
      timeZones: ['America/Creston', 'America/Anchorage', 'Pacific/Honolulu', 'America/New_York', 'America/Los_Angeles'],
      detailObj: null,
      isGivePermissionToStop: true,
      secondPin: 0,
      msgPerMin: 0,
      perMinApi: 0,
      popupError: false,
      istart: false,
      assignStaff: [
        {name: 'Rich Martin'},
        {name: 'Erika Cowell'},
        {name: 'Michael Bloomberg'},
        {name: 'Chris Rock'},
        {name: 'Dave Goodman'}
      ],
      readMoreNote: false,
      datePickerVal: false,
      updateExpiryDate: '',
      openEditPopup: false,
      isLoading: false,
      compDetail: null,
      planDetail: [],
      scY: 0,
      scTimer: 0,
      useDetailId: 0,
      isShowFullDesc: true,
      isShowShortDesc: true,
      mobileView: false,
      errorMsg: '',
      secondErrorMsg: '',
      competitionDetailObj: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.updateExpiryDate = new Date()
    window.scrollTo({top: 0})
    window.addEventListener('scroll', this.handleScroll);
    this.resizeWindowHandler();
    document.title = 'Campaign Detail'
    this.getCompetDetail()
    this.getMessageData()
    this.getUserStaffList()
  },
  beforeDestroy() {
    // Clear interval when component is destroyed to prevent memory leaks
    clearInterval(this.interval);
  },
  watch: {
    competitionDetailObj: {
      handler () {
        console.log('Watch On:-competitionDetailObj', this.competitionDetailObj)
      },
      deep: true
    },
    msgPerMin: {
      handler () {
        console.log('Watch On:-msgPerMin', this.msgPerMin)
        if (this.msgPerMin > this.perMinApi) {
          this.errorMsg = `You can send maximum ${this.perMinApi} messages per minute`
        } else {
          this.errorMsg = ''
        }
      },
      deep: true
    },
    secondPin: {
      handler () {
        console.log('Watch On:-secondPin', this.secondPin)
        if (this.secondPin > this.perMinApi) {
          this.secondErrorMsg = `You can send maximum ${this.perMinApi} messages per minute`
        } else {
          this.secondErrorMsg = ''
        }
      },
      deep: true
    }
  },
  methods: {
    updateTime() {
      // const now = new Date();
      // const selectedTimeZoneIndex = this.timeZones.indexOf(this.detailObj.timeZoneValue);
      // const timeZone = selectedTimeZoneIndex !== -1 ? this.timeZones[selectedTimeZoneIndex] : this.timeZones[0]; // Default to first time zone if selectedTimeZone not found
      // const currentTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds()).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: timeZone });
      // this.currentTime = currentTime;
      const now = new Date();
      const selectedTimeZoneIndex = this.timeZones.indexOf(this.detailObj.timeZoneValue);
      const timeZone = selectedTimeZoneIndex !== -1 ? this.timeZones[selectedTimeZoneIndex] : this.timeZones[0]; // Default to first time zone if selectedTimeZone not found

      const options = { 
          year: 'numeric', 
          month: 'numeric', 
          day: 'numeric',
          hour: 'numeric', 
          minute: 'numeric', 
          second: 'numeric', 
          timeZone: timeZone 
      };

      const currentTime = now.toLocaleString('en-US', options);
      this.currentTime = currentTime;
    },
    getMessageData () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.GetMessageLimit(
        response => {
          this.perMinApi = response.Data.perMin
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    button1 () {
      let url = this.$router.resolve({name: 'campaignAdd', params: {campaignMasterId: 0}, query: {campaignMasterId: parseInt(this.detailObj.campaignMasterId)}})
      window.open(url.href, '_blank')
      // this.$router.push({name: 'campaignAdd', params: {campaignMasterId: this.detailObj.campaignMasterId}})
      // this.$store.dispatch('SetAlert', {showAlert: true, message: 'Copied successfully', color: 'success'})
    },
    button2 () {
      this.$router.push({name: 'campaignEdit', params: {campaignMasterId: this.detailObj.campaignMasterId}})
    },
    dataRedirect (data) {
      console.log('data', data)
      let url = this.$router.resolve({name: 'subListListing', params: {listId: data.contactListMasterId}})
      window.open(url.href, '_blank')
    },
    deleteCampign () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.DeleteCampaign(
        this.detailObj.campaignMasterId,
        response => {
          this.yesinputText = ''
          this.popupError = false
          this.$router.push({name: 'Campaigns'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    addNewComp () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.StopCampaign(
        parseInt(this.$route.params.campaignMasterId),
        response => {
          this.getCompetDetail()
          this.getMessageData()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    isWorkPnding (data) {
      if (!data) {
        this.countOfErr = 0
        this.istart = false
      } else {
        this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, starting campaign...'})
          ADMINAPI.StartCampaign(
            parseInt(this.$route.params.campaignMasterId),
            this.msgPerMin,
            this.isGivePermissionToStop,
            response => {
              this.istart = false
              this.getCompetDetail()
              this.getMessageData()
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            },
            error => {
              this.isLoading = false
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
      }
    },
    startCampaign () {
      // if (this.msgPerMin === 0) {
      //   this.errorMsg = 'Please add value greater than 0'
      // } else if (this.msgPerMin === '') {
      //   this.errorMsg = 'Please add value'
      // }
      // if (this.errorMsg === '') {
        ADMINAPI.CheckStartTimeAlert(
          parseInt(this.$route.params.campaignMasterId),
          response => {
            console.log(response.message)
            this.timeCheckError = response.message
            if (response.message !== '') {
              this.istart = true
            } else {
              this.isWorkPnding(true)
            }
            // this.checkALertMessage()
          },
          error => {
            this.isLoading = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      // }
    },
    checkALertMessage () {
      ADMINAPI.StartCampaignAlert(
        parseInt(this.$route.params.campaignMasterId),
        response => {
          console.log('res', response.Data)
          this.countOfErr = response.Data
          this.istart = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    changesinStaff (data) {
      if (!data) {
        this.tempAssignee = JSON.parse(JSON.stringify(this.detailObj.campaignAssignees)) 
        this.actualAssignee = JSON.parse(JSON.stringify(this.detailObj.campaignAssignees)) 
        this.isChagesStaff = !this.isChagesStaff
        this.assigniErr = ''
      } else {
        if (this.tempAssignee.length === 0) {
          this.assigniErr = 'Please assign at least 1 user'
        } else {
          this.assigniErr = ''
        }
        if (this.assigniErr === '') {
          console.log('this.tempAssignee', this.tempAssignee)
          this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
          ADMINAPI.UpdateAssigneeinDetail(
            parseInt(this.detailObj.campaignMasterId),
            this.tempAssignee,
            response => {
              this.isChagesStaff = false
              this.getCompetDetail()
              this.getMessageData()
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            },
            error => {
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        }
      }
    },
    openRunningChange (data) {
      if (!data) {
        this.msgPerMin = this.detailObj.sendMeesagePerMinute
        this.isChangeValue = !this.isChangeValue
        this.errorMsg = ''
      } else {
        if (this.msgPerMin === 0) {
          this.errorMsg = 'Please add value ​​greater than 0'
        } else if (this.msgPerMin === '') {
          this.errorMsg = 'Please add value'
        }
        if (this.errorMsg === '') {
          console.log('this.msgPerMin', this.msgPerMin)
          this.isChangeValue = !this.isChangeValue
        }
      }
    },
    checkboxCheck () {
      this.isGivePermissionToStop = !this.isGivePermissionToStop
    },
    saveUpdate () {
      console.log('this.updateExpiryDate', this.updateExpiryDate)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.UpdateExpiryDate(
        this.planDetail.subscriptionId,
        this.updateExpiryDate,
        '',
        response => {
          this.openEditPopup = false
          this.getCompetDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    smoothScroll (target) {
      window.scrollTo({
        top: document.getElementById(target).offsetTop - 60,
        left: 0,
        behavior: "smooth",
      });
    },
    resizeWindowHandler() {
      if (window.innerWidth < 768) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    handleScroll () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop () {
      window.scrollTo({top: 0, behavior: "smooth"})
    },
    redirectToList() {
      // this.$router.push({name: 'Competition'})
      this.$router.go(-1)
    },
    getCompetDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetCampaignDetail(
        parseInt(this.$route.params.campaignMasterId),
        response => {
          let detailObj = response.Data === null ? null : response.Data
          document.title = 'Detail: ' + detailObj.campaignName
          detailObj.clockInTime = detailObj.campaignStartTime
          detailObj.clockOutTime = detailObj.campaignEndTime
          let data = JSON.parse(detailObj.days)
          let dataA = []
          for (let II = 0; II < this.dayVal.length; II++) {
            for (let JJ = 0; JJ < data.length; JJ++) {
              if (this.dayVal[II].id === data[JJ]) {
                this.dayVal[II].isSelected = true
                dataA.push(this.dayVal[II])
              }
            }
          }
          console.log('detailObj.campaignAssignees', detailObj.campaignAssignees)
          let temp = detailObj.campaignAssignees !== null ? detailObj.campaignAssignees : []
          temp.forEach(element => {
            element.firstName = element.assigneeFirstName
            element.lastName = element.assigneeLastName
            element.displayText = element.assigneeFirstName + ' ' + element.assigneeLastName
          });
          detailObj.campaignAssignees = JSON.parse(JSON.stringify(temp))
          detailObj.selectedDay = dataA
          this.detailObj = detailObj
          this.tempAssignee = this.detailObj.campaignAssignees
          this.actualAssignee = this.detailObj.campaignAssignees
          this.msgPerMin = this.detailObj.sendMeesagePerMinute
          this.isLoading = false
          this.updateTime();
          this.interval = setInterval(this.updateTime, 1000);
          console.log("response 123", this.competitionDetailObj)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    selectEmployee (emplist) {
      console.log('emplist', emplist)
      this.assigniErr = ''
      this.tempAssignee = []
      emplist.map((data) => {
        let obj = {
          assigneeUserId: data.assigneeUserId,
          displayText: data.displayText,
          assigneeFirstName: data.firstName,
          assigneeLastName: data.lastName,
        }
        this.tempAssignee.push(obj)
      })
    },
    getUserStaffList () {
      console.log('this.routing', this.orgId)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetStaffList(
        0,
        40,
        '',
        '',
        '',
        true,
        response => {
          let temp = response.Data.tableRow === null ? [] : response.Data.tableRow
          for (let index = 0; index < temp.length; index++) {
            temp[index].assigneeUserId = temp[index].userDetailId
            temp[index].assigneeFirstName = temp[index].firstName
            temp[index].assigneeLastName = temp[index].lastName
            temp[index].displayText = temp[index].firstName + ' ' + temp[index].lastName
          }
          this.currentyList = temp
          console.log(this.currentyList)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
  }
}
</script>
<style scoped>

.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.list_top_card_btn {
  width: 83px !important;
  margin-right: 15px !important;
}
.ImageTag {
  max-height: 300px;
  width: auto;
}
.page-number-input{
    width: 55px;
    display: inline-block;
    height: 30px;
    border-radius: 5px;
}
.d_icon_child {
  position: relative;
}
.d_icon_child:hover .tooltiptext {
  visibility: visible;
}
.tooltiptext {
  visibility: hidden;
  width: 150px;
  color: #fff;
  text-align: center;
  padding: 0px 0px;
  background-color: #ffffff;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: -30px;
  left: -40px;
}
</style>
